<template>
  <div
    class="calendesk-file-input"
    :class="{ 'no-event': loading }"
    :style="{ height: height, width: width }"
    @dragover.prevent
    @drop.prevent="dropFiles"
  >
    <div class="calendesk-file-input__content text-center">
      <span class="text-h6">{{ $trans("calendesk_file_input_first") }} </span>
      <span
        class="text-h6 calendesk-file-input__content--select-from-disk"
        @click="$refs.imageInputRef.click()"
        >{{ $trans("calendesk_file_input_second") }}
      </span>
      <span class="text-h6">{{ $trans("calendesk_file_input_third") }}</span>
    </div>
    <input
      v-if="inputFileReady"
      ref="imageInputRef"
      class="d-none"
      type="file"
      multiple
      :accept="accept"
      @change="uploadFileHandle"
    />
  </div>
</template>

<script>
export default {
  name: "FileInput",
  props: {
    accept: {
      type: String,
      default: "image/*",
    },
    height: {
      type: String,
      default: "100%",
    },
    width: {
      type: String,
      default: "100%",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      file: null,
      inputFileReady: true,
    };
  },
  watch: {
    file() {
      if (!this.file) return;
      this.$emit("change", this.file);
      this.resetInputFile();
      this.file = null;
    },
  },
  methods: {
    uploadFileHandle(e) {
      this.file = [...e.target.files];
    },
    dropFiles(e) {
      this.file = [...e.dataTransfer.files];
    },
    resetInputFile() {
      this.inputFileReady = false;
      this.$nextTick(() => {
        this.inputFileReady = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.calendesk-file-input {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border: 3px dashed #e0e0e0;
  border-radius: 1rem;
}

.calendesk-file-input__content--select-from-disk {
  color: var(--v-blue-base);
  cursor: pointer;
}

.no-event {
  pointer-events: none;
}
</style>
